<template>
  <!--  <el-row>-->
  <!--    <el-col class="main">-->
  <div class="home-main">
    <!--      <router-link to="/free-extract/62100df521fe468f813a62971c60a1c4/1">GO</router-link>-->
    <div class="header-v1">
      <div class="img">
        <picture>
          <source :srcset="bg2" media="(max-width: 1199px)">
          <img :src="bg1" alt="bg" style="width: 100%;">
        </picture>
      </div>
    </div>
    <div class="header-v2">
      <div class="download-group">
        <div class="btn">
          <el-button @click="handleDownload(1)">
            <el-image :src="ios1" class="btn-icon" fit="fill"/>
            <span class="btn-text">iOS下载</span>
          </el-button>
        </div>
        <div class="btn">
          <el-button @click="handleDownload(2)">
            <el-image :src="andriod1" class="btn-icon" fit="fill"/>
            <span class="btn-text">安卓下载</span>
          </el-button>
        </div>
      </div>
      <div class="qrcode">
        <div id="qrcode" style="padding: 6px"/>
      </div>
    </div>
    <div class="header-v3">
      <div class="header-v3-box">
        <div class="btn">
          <el-button @click="handleDownload(1)">
            <el-image :src="ios2" class="btn-icon" fit="fill"/>
            <span class="btn-text">iOS下载</span>
          </el-button>
        </div>
        <div class="btn" @click="handleDownload(2)">
          <el-button class="andriod-btn">
            <el-image :src="andriod2" class="btn-icon andriod-btn" fit="fill"/>
            <span class="btn-text andriod-text">安卓下载</span>
          </el-button>
        </div>
      </div>
    </div>
  </div>
  <!--    </el-col>-->
  <!--  </el-row>-->
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import {useRouter} from "vue-router"
import QRCode from 'qrcodejs2-fix'

const router = useRouter();

const bg1 = ref('')
const bg2 = ref('')
const ios1 = ref('')
const ios2 = ref('')
const andriod1 = ref('')
const andriod2 = ref('')

const H5Uri = computed(() => {
  return 'http://dx.wellstout.com'
})

// 安卓apk
const apkUri = computed(() => {
  return 'https://dxres.wellstout.com/apk/download/dxplay-release-1.31.1-wellstout.apk'
})

// iOS appStore
const appStoreUri = computed(() => {
  return 'https://apps.apple.com/cn/app/%E4%B8%9C%E8%A5%BF%E6%BD%AE%E7%8E%A9/id1672922557'
})

async function getIconImg() {
  const icon1 = await import('/public/static/images/web/ios@2x.png')
  const icon2 = await import('/public/static/images/web/ios@2x.png')

  const icon3 = await import('/public/static/images/h5/andriod@2x.png')
  const icon4 = await import('/public/static/images/h5/andriod@2x.png')

  ios1.value = icon1.default
  ios2.value = icon2.default
  andriod1.value = icon3.default
  andriod2.value = icon4.default
  generateCode()
}

async function getBgImg() {
  const bgImg1 = await import('/public/static/images/web/Header@2x.webp')
  const bgImg2 = await import('/public/static/images/h5/header-2.webp')
  bg1.value = bgImg1.default
  bg2.value = bgImg2.default
}

function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(userAgent)
}

/**
 * 唤起下载或跳转应用市场
 * type 1:ios 2:android
 * */
function handleDownload(type) {
  const fileName = 'dxcw'
  if (type === 1) {
    handleDownloadFile(appStoreUri.value, fileName, type)
  }
  if (type === 2) {
    handleDownloadFile(apkUri.value, fileName, type)

    // if (isMobileDevice()) {
    //    router.push('/download')
    //    window.open(`${window.location.origin}/#/download`, '_parent')
    // } else {
    //   handleDownloadFile(apkUri.value, fileName, type)
    // }
    // handleDownloadFile(apkUri.value, fileName, type)
    // router.push('/download')
  }
}

// 下载方法
function handleDownloadFile(url, fileName, type) {
  if (type === 1) {
    window.location.href = url
  }
  if (type === 2) {
    const a = document.createElement('a')
    a.download = fileName
    a.style.display = 'none'
    a.href = url
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
}

// 生成二维码
function generateCode() {
  document.getElementById('qrcode').innerHTML = ''
  new QRCode(document.getElementById('qrcode'), {
    text: `${window.location.origin}/#/download`,
    width: 128,
    height: 128
  })
}

onMounted(() => {
  getBgImg()
  getIconImg()
})
</script>

<style lang="scss" scoped>
.home-main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  //overflow: hidden;
  //overflow-y: scroll;
  //-webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 1199px) {
  .header-v2 {
    display: none !important;
  }
  .header-v3 {
    display: inline-flex !important;
  }
}

@media screen and (min-width: 1200px) {
  .header-v2 {
    display: inline-flex !important;
  }
  .header-v3 {
    display: none !important;
  }
}

.img {
  width: 100%;
  height: 100%;

  /deep/ img {
    width: 100%;
    height: 100%;
  }
}

.header-v1 {
  position: relative;
  width: 100%;
  height: 100%;
}

.header-v2 {
  width: 344px;
  display: inline-flex;
  justify-content: space-between;
  position: absolute;
  top: 60%;
  left: 21.5%;

  .download-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btn:first-child {
    margin-bottom: 20px;
  }

  /deep/ .el-button {
    width: 180px;
    height: 56px;
    line-height: 56px;
    border-radius: 28px;
  }

  .btn-icon {
    width: 30px;
    height: 30px;
  }

  .btn-text {
    font-family: PingFangSC-Medium;
    font-weight: 600;
    font-size: 14px;
    color: #1D2129;
    letter-spacing: 0;
    text-align: center;
    padding-left: 9px;
  }

  .qrcode {
    width: 140px;
    height: 140px;
    background-color: aliceblue;
    border-radius: 8px;
  }
}

.header-v3 {
  width: 100%;
  position: absolute;
  top: 64%;
  left: 0;

  .header-v3-box {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      /deep/ .el-button {
        width: 150px !important;
        height: 90px;
        line-height: 90px;
        border-radius: 12px;
      }
      padding: 0 !important;
    }

    @media screen and (max-width: 700px) {
      /deep/ .el-button {
        width: 200px !important;
        height: 90px;
        line-height: 90px;
        border-radius: 12px;
      }
      padding: 0 20px !important;
    }

    @media screen and (min-width: 700px) {
      padding: 0 40px !important;
    }

    @media screen and (min-width: 761px) {
      padding: 0 60px !important;
    }

    @media screen and (min-width: 1100px) {
      padding: 0 60px !important;
    }
  }

  .btn:first-child {
    margin-bottom: 20px;
  }

  /deep/ .el-button {
    width: 300px;
    height: 90px;
    line-height: 90px;
    border-radius: 12px;
  }

  .andriod-btn {
    background: #1E1E1E;
    border-color: #1E1E1E;
  }

  .btn-icon {
    width: 40px;
    height: 40px;
  }

  .btn-text {
    font-family: PingFangSC-Medium;
    font-weight: 600;
    font-size: 24px;
    color: #1D2129;
    letter-spacing: 0;
    text-align: center;
    padding-left: 9px;
  }

  .andriod-text {
    color: #FFFFFF !important;
  }
}
</style>
