<template>
  <div class="main-container">
    <div class="main-bg">
      <img :src="bg" alt="bg" style="width: 100%;">
    </div>
    <div class="btn-container">
      <div class="btn-box">
        <button class="app-btn" @click="handleDownload(1)">
          <div class="btn-icon">
            <img :src="ios1" fit="fill" style="width: 30px;height: 30px;"/>
          </div>
          <div class="btn-text">iOS下载</div>
        </button>
        <button class="app-btn" @click="handleDownload(2)">
          <div class="btn-icon">
            <img :src="andriod1" fit="fill" style="width: 30px;height: 30px;"/>
          </div>
          <div class="btn-text">安卓下载</div>
        </button>
      </div>
    </div>

    <div v-if="openMarkStatus" class="open-mark">
      <img :src="markImg" alt="markImg" style="width: 100%;height: 100vh;">
    </div>
  </div>
</template>

<script setup>

// 安卓apk
import {computed, onMounted, ref} from "vue";

const bg = ref('')
const ios1 = ref('')
const ios2 = ref('')
const andriod1 = ref('')
const andriod2 = ref('')

const openMarkStatus = ref(false)
const markImg = ref('')
const viewWidth = ref(0)

const apkUri = computed(() => {
  return 'https://dxres.wellstout.com/apk/download/dxplay-release-1.31.1-wellstout.apk'
})

// iOS appStore
const appStoreUri = computed(() => {
  return 'https://apps.apple.com/cn/app/%E4%B8%9C%E8%A5%BF%E6%BD%AE%E7%8E%A9/id1672922557'
})

const iOSSchemeUrl = 'wellstout://web'
const andriodSchemeUrl = 'dx://tideplay:9999/mainActivity'

async function getIconImg() {
  const icon1 = await import('/public/static/images/web/ios@2x.png')
  const icon2 = await import('/public/static/images/web/ios@2x.png')

  const icon3 = await import('/public/static/images/h5/andriod@2x.png')
  const icon4 = await import('/public/static/images/h5/andriod@2x.png')

  ios1.value = icon1.default
  ios2.value = icon2.default
  andriod1.value = icon3.default
  andriod2.value = icon4.default
}

async function getBgImg() {
  const bgImg1 = await import('/public/static/images/h5/user-activity.png')
  const img = await import('/public/static/images/h5/openMark.png')
  markImg.value = img.default
  bg.value = bgImg1.default
}

// 用户打开官网网页判断机型以schemeURL打开APP或跳转应用市场、下载apk
async function openApp() {
  if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
    if (isWeixin()) {
      setMarkOpen()
      return
    }
    const loadDateTime = new Date()
    // 打开 ios 端软件schemeURL
    window.open(iOSSchemeUrl, '_parent')
    window.setTimeout(function () {
      const timeOutDateTime = new Date()
      if (timeOutDateTime - loadDateTime < 5000) {
        handleDownload(1)
      } else {
        window.close()
      }
    }, 25)
  } else if (navigator.userAgent.match(/android/i)) {
    if (isWeixin()) {
      setMarkOpen()
      return
    }
    const loadDateTime = new Date()
    // 打开 Android 端软件schemeURL
    window.open(andriodSchemeUrl, '_parent')
    window.setTimeout(function () {
      const timeOutDateTime = new Date()
      if (timeOutDateTime - loadDateTime < 5000) {
        handleDownload(2)
      } else {
        window.close()
      }
    }, 25)
  }
}

// 判断微信浏览器
function isWeixin() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.match(/MicroMessenger/i) == 'micromessenger'
}

// 打开遮罩层并禁用浏览器页面滚动条
function setMarkOpen() {
  openMarkStatus.value = true
  const bodyEl = document.body.style
  bodyEl.overflow = 'hidden'
  bodyEl.position = 'fixed'
  bodyEl.width = '100%'
}

/**
 * 唤起下载或跳转应用市场
 * type 1:ios 2:android
 * */
function handleDownload(type) {
  const fileName = 'dxcw'
  if (type === 1) {
    handleDownloadFile(appStoreUri.value, fileName, type)
  }
  if (type === 2) {
    handleDownloadFile(apkUri.value, fileName, type)
  }
}

// 下载方法
function handleDownloadFile(url, fileName, type) {
  if (type === 1) {
    window.location.href = url
  }
  if (type === 2) {
    const a = document.createElement('a')
    a.download = fileName
    a.style.display = 'none'
    a.href = url
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
}

onMounted(() => {
  getIconImg()
  getBgImg()
  openApp()
})
</script>

<style lang="scss" scoped>
/* 整个滚动条 */
::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 10px;
  /* 对应横向滚动条的宽度 */
  height: 10px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: #796EEF;
  border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #A09AF2;
  border-radius: 32px;
}

.main-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  //overflow-y: scroll;
  //-webkit-overflow-scrolling: touch;

  .main-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    /deep/ img {
      width: 100%;
      height: 100%;
    }

  }

  .btn-container {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 60%;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-box {
      width: 96%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .app-btn {
        width: 160px;
        height: 56px;
        line-height: 56px;
        border-radius: 28px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-icon {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          /deep/ img {
            width: 30px;
            height: 30px;
          }

        }

        .btn-text {
          font-family: PingFangSC-Medium;
          font-weight: 600;
          font-size: 24px;
          color: #1D2129;
          letter-spacing: 0;
          text-align: center;
          padding-left: 9px;
        }

        .andriod-text {
          color: #FFFFFF !important;
        }
      }
    }
  }
}

.header {
  width: 100%;
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: #796EEF;
  font-size: 22px;
}

.open-mark {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}
</style>
